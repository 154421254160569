<template>
    <v-container fluid class="px-8" >
        <v-row>
            <v-col class="d-flex" >
                <v-btn
                    tile
                    dark
                    color="orange"
                    class="font-weight-bold text-body-1"
                    @click.stop="openEditCourseDlg()"
                    v-if="isPrivate"
                >
                    <v-icon left>
                        mdi-plus-circle-outline
                    </v-icon>
                    增加课程
                </v-btn>
            </v-col>

            <v-col class="d-flex justify-center" >
                <v-btn
                    class="btn-font"
                    style="margin: 0;"
                    tile
                    dark
                    depressed
                    color="indigo"
                    :outlined="!isPrivate"
                    @click="isPrivate = true"
                >
                    自有课程
                </v-btn>
                <v-btn
                    class="btn-font"
                    style="margin: 0;"
                    tile
                    dark
                    depressed
                    color="indigo"
                    :outlined="isPrivate"
                    @click="isPrivate = false"
                >
                    公共课程
                </v-btn>
            </v-col>

            <v-col class="d-flex" >
                <div class="option-area">
                    <div class="option-item">
                        <v-select
                            v-model="courseType"
                            :items="courseTypes"
                            color="grey lighten-2"
                            item-text="name"
                            item-value="value"
                            placeholder="课程类型"
                            dense
                            outlined
                            @change="loadData"
                        ></v-select>
                    </div>
                </div>
            </v-col>
        </v-row>

        <!-- 课程列表 -->
        <v-data-table
            :headers="headers4Private"
            :items="data4PrivateCourse"
            :items-per-page-options="[5, 10, 15, -1]"
            class="elevation-2 row-pointer"
            :hide-default-footer="data4PrivateCourse.length <= 10"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageText: '每页行数',
                pageText: '',
                'items-per-page-all-text': '全部',
            }"
            @click:row="clickRow"
            v-if="isPrivate"
        >
            <template v-slot:no-data>
                暂无课程
            </template>
            <template v-slot:item.name="{ item }">
                <!-- <v-chip color="purple" dark class="font-weight-medium" @click.stop="goto(item.cid)">
                    {{ item.name }}
                </v-chip> -->
                <div class="td-line font-weight-medium">
                    <div class="active-name" @click.stop="goto(item.cid)" >
                        {{ item.name }}
                    </div>
                </div>
            </template>
            <template v-slot:item.course_type="{ item }">
                <v-chip :color="getTypeColor(item.course_type)" dark >
                    {{ getTypeFirstLetter(item.course_type) }}
                </v-chip>
            </template>
            <template v-slot:item.status="{ item }">
                <v-chip :color="item.status == 1 ? 'light-green lighten-1' : 'grey lighten-1'" small dark 
                        class="font-weight-medium"
                >
                    {{ getStatus(item.status) }}
                </v-chip>
            </template>
            <template v-slot:item.operate="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="purple"
                            @click.stop="openEditCourseDlg(item)"
                        >
                            <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                    </template>
                    <span>编辑课程</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="red darken-1"
                            @click.stop="openConfirmDlg(item.cid)"
                        >
                            <v-icon dark> mdi-trash-can </v-icon>
                        </v-btn>
                    </template>
                    <span>删除课程</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-data-table
            :headers="headers4Public"
            :items="data4PublicCourse"
            :items-per-page-options="[5, 10, 15, -1]"
            class="elevation-2 row-pointer"
            :hide-default-footer="data4PublicCourse.length <= 10"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageText: '每页行数',
                pageText: '',
                'items-per-page-all-text': '全部',
            }"
            @click:row="clickRow"
            v-else
        >
            <template v-slot:no-data>
                暂无课程
            </template>
            <template v-slot:item.name="{ item }">
                <div class="td-line font-weight-medium">
                    <div class="active-name" @click.stop="goto(item.cid)" >
                        {{ item.name }}
                    </div>
                </div>
            </template>
            <template v-slot:item.course_type="{ item }">
                <v-chip :color="getTypeColor(item.course_type)" dark >
                    {{ getTypeFirstLetter(item.course_type) }}
                </v-chip>
            </template>
        </v-data-table>

        <EditCourseDlg ref="editCourseDlg" @submit="submitCourse" />

        <ConfirmDlg
            ref="delCourseConfirmDlg"
            title="删除课程"
            text="确认 删除 当前课程么？"
            keyword=" 删除 "
            @confirm="delCourseConfirm"
        />

    </v-container>
</template>

<script>
import EditCourseDlg from '@/views/components/dialog/EditCourseDlg';
import ConfirmDlg from '@/views/components/dialog/ConfirmDlg';
import { getAdminAllCourseList, editAdminCourse, delAdminCourse } from "@/api/admin";
import { getTypeColor } from '@/utils/common';
import { getTypeFirstLetter } from "@/utils/util";

export default {
    name: "CourseList",

    data() {
        return {
            headers4Private: [
                { text: "课程名称", value: "name", align: "left" },
                { text: "类型", value: "course_type", width: 78 },
                { text: "知识点", value: "points", sortable: false },
                { text: "内容介绍", value: "desc", sortable: false },
                { text: "难度", value: "level", width: 80, align: "center", sortable: false, },
                { text: "状态", value: "status", width: 80, align: "center", sortable: false, },
                { text: "操作", value: "operate", sortable: false, align: "center", width: 130},
            ],
            headers4Public: [
                { text: "课程名称", value: "name", align: "left" },
                { text: "类型", value: "course_type", width: 78 },
                { text: "知识点", value: "points", sortable: false },
                { text: "内容介绍", value: "desc", sortable: false },
                { text: "难度", value: "level", width: 100, align: "center", sortable: false, },
            ],
            
            courseType: "",
            courseTypes: [
                { name: "全部课程", value: ""}, 
                { name: "Scratch", value: "SCRATCH"}, 
                { name: "Python", value: "PYTHON"}, 
                { name: "C/C++", value: "C"}, 
                { name: "机器人", value: "ROBOT"}, 
            ],

            data4PrivateCourse: [],
            data4PublicCourse: [],
            isPrivate: true,
        };
    },

    mounted() {
    },
    activated() {
        this.loadData();
    },

    methods: {
        getTypeColor,
        getTypeFirstLetter,
        getStatus(status) {
            if (status == 0) {
                return "待发布"
            } else if (status == 1) {
                return "已发布"
            }
        },
        loadData() {
            getAdminAllCourseList({scope: "PRIVATE", courseType: this.courseType}).then((response) => {
                // console.log(response.data);
                this.data4PrivateCourse = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });

            getAdminAllCourseList({scope: "PUBLIC", courseType: this.courseType}).then((response) => {
                // console.log(response.data);
                this.data4PublicCourse = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });
        },
        clickRow(item) {
            this.goto(item.cid);
        },
        goto(id) {
            this.$router.push({path: '/course-mgr/course-list/course', query: {id: id}});
        },

        openEditCourseDlg(course = null) {
            this.$refs.editCourseDlg.openDlg(course);
        },
        openConfirmDlg(cid) {
            this.$refs.delCourseConfirmDlg.openDlg(cid);
        },

        delCourseConfirm(cid) {
            delAdminCourse(cid).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    for (let i = 0; i < this.data4PrivateCourse.length; i++) {
                        if (this.data4PrivateCourse[i].cid == cid) {
                            this.data4PrivateCourse.splice(i, 1)
                            break
                        }
                    }

                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "删除课程成功",
                    });

                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "删除课程失败",
                    });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        submitCourse(newCourse) {
            editAdminCourse({
                cid: newCourse.id,
                name: newCourse.name, 
                courseType: newCourse.course_type,
                desc: newCourse.desc,
                points: newCourse.points,
                level: newCourse.level,
                cover: newCourse.cover,
            }).then((response) => {
                // console.log(response.data)
                if (response.msg == 'SUCCESS') {
                    this.$store.dispatch('web/SetAlert', {type: "success", msg: "创建课程成功"});

                    // 更新页面数据
                    let result = response.data
                    if (newCourse.id == "") {
                        // 创建，添加到列表开头
                        this.data4PrivateCourse.unshift(result)

                    } else {
                        // 修改
                        for (let i in this.data4PrivateCourse) {
                            if (this.data4PrivateCourse[i].cid == newCourse.id) {
                                this.data4PrivateCourse[i].name = newCourse.name
                                this.data4PrivateCourse[i].course_type = newCourse.course_type
                                this.data4PrivateCourse[i].desc = newCourse.desc
                                this.data4PrivateCourse[i].points = newCourse.points
                                this.data4PrivateCourse[i].level = newCourse.level
                                this.data4PrivateCourse[i].cover = newCourse.cover
                            }
                        }
                    }

                } else {
                    this.$store.dispatch('web/SetAlert', {type: "error", msg: "创建课程失败"});
                }

            }).catch(function (err) {
                console.log(err);
            });
        },
    },

    components: {
        EditCourseDlg,
        ConfirmDlg,
    }
};
</script>

<style lang="scss" scoped>
.option-area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .option-item {
        width: 150px;
    }
}
.btn-font {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
}

.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.td-line {
    text-align: left;
    display: flex;
    justify-content: left;
    height: unset !important;
    // .active-name {
    //     padding: 8px 25px;
    //     border-radius: 50px;
    //     background-color: #fff;
    //     &:hover {
    //         cursor: pointer;
    //         background-color: #fff;
    //         border-radius: 50px;
    //     }
    // }
}
</style>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
